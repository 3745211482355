<template>
  <b-modal
    :visible="showModal"
    title="Analise seguro"
    :hide-footer="true"
    class="fontt-modal"
    @hide="hideModal"
    size="lg"
  >

    <div class="fontt-modal">
      <div class="row">
        <div class="col-6">
          <div class="mb-2 sub-texto"><strong>Dados pessoais</strong></div>
          <div class="mb-1">
            <strong>Nome: </strong>{{ seguro.nomeCliente }}
          </div>
          <div class="mb-1">
            <strong>Telefone: </strong
            >{{ formatacaoTelefone(seguro.telefoneCliente) }}
          </div>
          <div class="mb-1">
            <strong>E-mail: </strong>{{ seguro.emailCliente }}
          </div>
          <div class="mb-1">
            <strong>Cpf: </strong>{{ helper.formatacaoCpf(seguro.cpfCliente) }}
          </div>
        </div>
        <div class="col-6">
          <div class="mb-1 sub-texto"><strong>Dados da bike</strong></div>
          <div class="mb-1"><strong>Ano: </strong>{{ seguro.anoBike }}</div>
          <div class="mb-1">
            <strong>Valor: </strong>{{ formatacaoMoeda(seguro.valorBike) }}
          </div>
          <div class="mb-1"><strong>Marca: </strong>{{ seguro.marcaBike }}</div>
          <div class="mb-1">
            <strong>Modelo: </strong>{{ seguro.modeloBike }}
          </div>
        </div>
      </div>
      <hr />
      <div class="row mt-2">
        <div class="mb-2 col-12 sub-texto"><strong>Dados seguro</strong></div>
        <div class="mb-1 col-6"><strong>Plano: </strong>{{ seguro.plano }}</div>
        <div class="mb-1 col-6">
          <strong>Tipo de plano: </strong>{{ seguro.nomeTipoAssinatura }}
        </div>
        <div class="mb-1 col-6">
          <strong>Data checkout: </strong>
          {{ formatacaoData.formatarDataLocalsemUtc(seguro.dataCheckout) }}
        </div>
        <div class="mb-1 col-6">
          <strong>Valor plano: </strong>{{ formatacaoMoeda(seguro.valorPlano) }}
        </div>
        <div class="mb-1 col-6">
          <strong>Situação: </strong>
          <b-badge variant="info"> {{ seguro.situacaoSeguro }}</b-badge>
        </div>
        <div class="mb-1 col-12" v-if="seguro.urlApolice">
          <strong>UrlApolice: </strong>
          <a
            :href="seguro.urlApolice"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ seguro.urlApolice }}</a
          >
        </div>
      </div>
    </div>
 <Loading :criandoSeguro="true" v-if="loading" />
  </b-modal>
</template>

<script>
import config from "../../config";
import axios from "axios";
import formatacaoMoeda from "../../helpers/formatacaoMoeda2";
import helper from "../../helpers/helper";
import formatacaoData from "../../helpers/formatacaoData";
import formatacaoTelefone from "../../helpers/formatacaoTelefone";
import Loading from "../../components/Usuario/Loading.vue";
export default {
  props: ["id", "showModal"],
  components: {Loading},
  data() {
    return {
      config,
      helper,
      formatacaoData,
      formatacaoTelefone,
      formatacaoMoeda,
      loading: false,
      seguro: {},
    };
  },
  methods: {
    hideModal() {
      this.$emit("hide");
    },
    async buscar() {
      try {
        this.seguro.urlApolice = null;
        this.loading = true;
        let response = await axios.get(`${config.API_URLV2}/seguro/${this.id}`);
        this.seguro = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$toast.error("não foi possivel buscar o seguro");
      }
    },
  },
  watch: {
    id: function(newValue, oldValue) {
      if (newValue != oldValue) {
        this.buscar();
      }
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
.fontt-modal {
  font-family: "Montserrat";
}
.sub-texto {
  font-size: 20px;
}
</style>
